import React, {useEffect, useState} from 'react'
import { compare } from './globalFunction';
import { useLocation, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';

const Navbar = (props)=>{
  
    /* 
  {
    "menuName": "Fees",
    "description": "Fees Management",
    "menuIcon": "fa  fa-fw fa-money",
    "status": true,
    "menuUrl": "",
    "order": "4",
    "ID": "ze0bdq77p",
    "submenu": [
      {
        "subName": "Fees Payment",
        "subUrl": "/fees/fees_payment",
        "subDescription": "Fees Payment",
        "status": true,
        "ID": "tb3un1zuz",
        "subsubmenu": []
      },
      {
        "subName": "Family Fees",
        "subUrl": "/fees/family_fees",
        "subDescription": "Family Fees",
        "status": true,
        "ID": "2yrsuzyff",
        "subsubmenu": []
      }
    ]
  } */


  let location =  useLocation()
  const menu  = useSelector(state => state.rootReducer.sidebar);
  
  const [isDisplay, setIsDisplay] = useState({ "0": true });
  const [isDisplaySubmenu, setIsDisplaySubmenu] = useState({ [props.submenu]: true });
  const handleToggleDisplaySubmenu=(main, sub)=>{
  // handleToggleDisplay(main)
  if(sub!=='0'){
  
  setIsDisplay({[main]: true});
  setIsDisplaySubmenu({[sub]: !isDisplaySubmenu[sub]});
  }else{
  setIsDisplay({[main]: !isDisplay[main]})
  }
  }
  
  
  let activeHasmenuOpen ='pcoded-hasmenu pcoded-trigger active'

   const menuList = [
    {
      "menuName": "Dashboard",
      "description": "Display website usage statistics",
      "menuIcon": "fa fa-fw fa-dashboard",
      "status": true,
      "menuUrl": "/dashboard",
      "order": 1,
      "ID": "aw4jkqzjw",
      "submenu": []
    },
    {
      "menuName": "Academics",
      "description": "Examination Management",
      "menuIcon": "fa  fa-fw fa-file-text",
      "status": true,
      "menuUrl": "",
      "order": "3",
      "ID": "cw13tmtoo",
      "submenu": [
        {
            "subName": "Exam Schedule",
            "subUrl": "/exam/exam_schedule",
            "subDescription": "Exam Schedule",
            "status": true,
            "ID": "dgkjpet2e",
            "subsubmenu": []
          },
          {
            "subName": "Mid Term Result",
            "subUrl": "/exam/mid_term",
            "subDescription": "Student Mid term Result",
            "status": true,
            "ID": "dgkjdpet2e",
            "subsubmenu": []
          },
        {
          "subName": "Terminal Result",
          "subUrl": "/exam/myresult",
          "subDescription": "Student Result",
          "status": true,
          "ID": "dgkjpet2e",
          "subsubmenu": []
        },
        {
          "subName": "Transcript",
          "subUrl": "/exam/mytranscript",
          "subDescription": "Transcript",
          "status": true,
          "ID": "nlfd0a7uf",
          "subsubmenu": []
        }
      ]
    },
    
    {
      "menuName": "Attendance",
      "description": " Student attendance",
      "menuIcon": "fa  fa-fw fa-calendar",
      "status": true,
      "menuUrl": "",
      "order": "6",
      "ID": "6uabdn9wm",
      "submenu": [
        
        {
          "subName": "Summary",
          "subUrl": "/attendance/student_attendance",
          "subDescription": "",
          "status": true,
          "ID": "fbrriuz2b",
          "subsubmenu": []
        },
        {
          "subName": "Late Arrival & Early Departure",
          "subUrl": "/attendance/student_arrival_departure",
          "subDescription": "",
          "status": true,
          "ID": "kov9cnxvp",
          "subsubmenu": []
        }
      ]
    },
    {
      "menuName": "Library",
      "description": "Library books",
      "menuIcon": "fa  fa-fw fa-book",
      "status": true,
      "menuUrl": "",
      "order": "9",
      "ID": "nhn0mxac0",
      "submenu": [
        
        {
          "subName": "Book Availability",
          "subUrl": "/library/books",
          "subDescription": "",
          "status": true,
          "ID": "ks2xr2k0n",
          "subsubmenu": []
        },
        {
          "subName": "E-Library",
          "subUrl": "/library/elibrary",
          "subDescription": "",
          "status": true,
          "ID": "dar95nzqk",
          "subsubmenu": []
        },
        {
          "subName": "Library Due",
          "subUrl": "/library/books_report",
          "subDescription": "",
          "status": true,
          "ID": "507rg60si",
          "subsubmenu": []
        }
      ]
    },
    {
      "menuName": "Timetable",
      "description": "Class Timetable",
      "menuIcon": "fa  fa-fw fa-table",
      "status": true,
      "menuUrl": "",
      "order": "10",
      "ID": "vlhb61kv1",
      "submenu": [
        
        {
          "subName": "Class Timetable",
          "subUrl": "/timetable/class_timetable",
          "subDescription": "",
          "status": true,
          "ID": "piefyfrxu",
          "subsubmenu": []
        },
        {
          "subName": "Lesson Plan",
          "subUrl": "/timetable/class_lesson_plan",
          "subDescription": "",
          "status": true,
          "ID": "oaki2zqnc",
          "subsubmenu": []
        },
        {
          "subName": "Holiday List",
          "subUrl": "/timetable/holidays",
          "subDescription": "",
          "status": true,
          "ID": "uaakk7drx",
          "subsubmenu": []
        }
      ]
    },
    {
      "menuName": "Hostel",
      "description": " Student Hostel",
      "menuIcon": "fa  fa-fw fa-building",
      "status": true,
      "menuUrl": "",
      "order": "6",
      "ID": "fhdudgd",
      "submenu": [
        
        {
          "subName": "Hostel Allocation",
          "subUrl": "/hostel/allocation",
          "subDescription": "",
          "status": true,
          "ID": "fbdgetsg",
          "subsubmenu": []
        },
        {
          "subName": "Allocation History",
          "subUrl": "/hostel/history",
          "subDescription": "",
          "status": true,
          "ID": "dyshts",
          "subsubmenu": []
        }
      ]
    },
    {
      "menuName": "Notice Board",
      "description": "School Noticeboard",
      "menuIcon": "fa  fa-fw fa-bar-chart",
      "status": true,
      "menuUrl": "/student/noticeboard",
      "order": "12",
      "ID": "a7otk1csq",
      "submenu": []
    },
    {
      "menuName": "Tickets",
      "description": "Send an issue",
      "menuIcon": "fa  fa-fw fa-envelope",
      "status": true,
      "menuUrl": "/tickets",
      "order": "14",
      "ID": "a7otsgsts",
      "submenu": []
    }
  ]



const fetchContent =()=>{

    let response = menuList.sort(compare)
    let menu = response.filter(item=>item.menuName===props.menu)[0]
    
     let submenu = menu.submenu.length!==0 && props.submenu !==''?menu.submenu.filter(item=>item.subName===props.submenu)[0]:{"ID":"0"}

     setIsDisplaySubmenu({[submenu.ID]:true})
     setIsDisplay({[menu.ID]:true})
   // setIsDisplaySubsubmenu({[subsub.ID]: true});
    //setMenu(response)

}

useEffect(()=>{
    //fetchContent() 
    },[]);

    return (  
      <nav className="pcoded-navbar no-print"  >
      <div className="pcoded-inner-navbar main-menu " >
          <div className="pcoded-navigatio-lavel" menu-title-theme="theme5" ></div>
          
          <ul className="pcoded-item pcoded-left-item no-print">
             {menu.length!==0?menu.map((data, index)=>data.isActive==='Yes'?
      <li  key={index} dropdown-icon={data.submenu.length!==0?"style2":''} subitem-icon={data.submenu.length!==0?"style2":''} 
      
      className={data.submenu.length ===0 && location.pathname===data.menuUrl?'active':
         
          isDisplay[data.ID] && props.menu===data.menuName && data.submenu.length!==0?activeHasmenuOpen:
          props.menu===data.menuName && data.submenu.length!==0?'pcoded-hasmenu active':
          isDisplay[data.ID] && data.submenu.length !==0 ?'pcoded-hasmenu pcoded-trigger':
      
      data.submenu.length !==0?'pcoded-hasmenu':  ''}
      
      >
                
{data.submenu.length!==0? <a href='#!' title={data.menuName} onClick={()=>handleToggleDisplaySubmenu(data.ID, '0')}>
<span className="pcoded-micon"><i className={data.menuIcon}></i></span>
                      <span className="pcoded-mtext ">{data.menuName}</span>

</a>:

<Link to={data.menuUrl} > 
 <span className="pcoded-micon"><i className={data.menuIcon}></i></span>
                      <span className="pcoded-mtext ">{data.menuName}</span>
               
                  </Link>
                  
                  }
                  {data.submenu.length!==0?
                  <ul className="pcoded-submenu">
                {data.submenu.map((smenu, sid)=>smenu.isActive==='Yes'?
        <li key ={sid} 

  className={smenu.subsubmenu.length ===0 && location.pathname===smenu.subUrl?'active':
  
      isDisplaySubmenu[smenu.ID] && smenu.subsubmenu.length !==0 && props.submenu===smenu.subName?activeHasmenuOpen: 
      
      isDisplay[data.ID] && isDisplaySubmenu[smenu.ID] && smenu.subsubmenu.length !==0 && props.submenu===smenu.subName ?'pcoded-hasmenu pcoded-trigger active': 
      

      isDisplaySubmenu[smenu.ID] && smenu.subsubmenu.length !==0?'pcoded-hasmenu pcoded-trigger':
       smenu.subsubmenu.length !==0?'pcoded-hasmenu':
                      ''}  
                      dropdown-icon={smenu.subsubmenu.length!==0?"style3":''} subitem-icon={smenu.subsubmenu.length!==0?"style3":''}
                      
                      >
              

{smenu.subsubmenu.length!==0?    <a href='#!'
                      onClick={()=>handleToggleDisplaySubmenu(data.ID, smenu.ID)}>

<span className="pcoded-micon "><i className="feather icon-menu"></i></span>
                      <span className="pcoded-mtext " >{smenu.subName}</span>

                      </a>  :    
<Link to={smenu.subUrl} >   
                      <span className="pcoded-micon"><i className="feather icon-menu"></i></span>
                      <span className="pcoded-mtext" >{smenu.subName}</span>
             
                  </Link>}
                  {smenu.subsubmenu.length!==0?
                  <ul className="pcoded-submenu">

                      {smenu.subsubmenu.map((subsub, k)=>subsub.isActive==='Yes'?
                              <li   className={location.pathname===subsub.subsubUrl?'active':'' } key={k}  >
                                
<Link to={subsub.subsubUrl} > 
                                      <span className="pcoded-mtext">{subsub.subsubName}</span>
                             
                                  </Link>
                              </li>:'')}

                          </ul>:''}
                      </li>:'')}
                     
                  </ul>:''}
              </li>:''):<div className='loadit'></div>}

              <li className="">
                  
              <a href="/">
                     
              <span className="pcoded-micon"><i className="feather icon-power"></i></span>
                                  <span className="pcoded-mtext">Log Out</span>
                  </a>
                          </li>

          </ul> 


      </div>
  </nav> );
}
export default React.memo(Navbar)