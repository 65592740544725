import Cookies from 'js-cookie'
var CryptoJS = require('crypto-js')

export const FormatNumber = (num)=>{
    
    let newNumber = Number(num)
    return  newNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}




export const APP_KEY =process.env.REACT_APP_APP_KEY;
export  const mainUrl = process.env.REACT_APP_MAIN_URL
export  const StudentUrl= "https://portal."+mainUrl
export  const teacherUrl= "https://staff."+mainUrl
export  const ParentUrl=  "https://parent."+mainUrl
export  const applicantUrl=  "https://form."+mainUrl
export  const ServerUrl = "https://app."+mainUrl+'/controller'
export  const ImagesUrl = "https://app."+mainUrl+'/images'

export  const LocalUrl = 'localhost'
export  const KAYUS_ULR = 'https://kayus.org'
export const DatabaseName= process.env.REACT_APP_DATABASE_NAME
export const domain = process.env.REACT_APP_DOMAIN
export const smsAccountUrl = process.env.REACT_APP_SMS_ACCOUNT_URL;



export const studentName = Cookies.get('hisgdstkstnm')?CryptoJS.AES.decrypt(Cookies.get('hisgdstkstnm'), APP_KEY).toString(CryptoJS.enc.Utf8):'null'
export const classID = Cookies.get('hisgdsclkysd')?CryptoJS.AES.decrypt(Cookies.get('hisgdsclkysd'), APP_KEY).toString(CryptoJS.enc.Utf8):'null'
export const sectionID = Cookies.get('hisgdssckayd')?CryptoJS.AES.decrypt(Cookies.get('hisgdssckayd'), APP_KEY).toString(CryptoJS.enc.Utf8):'null'

export const familyCode = Cookies.get('hisgdfmcdk')?CryptoJS.AES.decrypt(Cookies.get('hisgdfmcdk'), APP_KEY).toString(CryptoJS.enc.Utf8):'null'
export const studentCode= Cookies.get('hisgdstkauscd')?CryptoJS.AES.decrypt(Cookies.get('hisgdstkauscd'), APP_KEY).toString(CryptoJS.enc.Utf8):'null'
export const Token = Cookies.get('hisgdstkamtk')?CryptoJS.AES.decrypt(Cookies.get('hisgdstkamtk'), APP_KEY).toString(CryptoJS.enc.Utf8):'null'
export const config = {
    headers: { 'content-type': 'multipart/form-data'}
} 
export const Currency = '₦'

 



