import React, {useState, useEffect} from 'react'
import {Alerts} from '../component/notify'
import { ImagesUrl, KAYUS_ULR, ServerUrl, config} from '../component/include'
import axios from 'axios'
import Cookies from 'js-cookie'
import Seo from '../component/seo'
import {encrypt} from '../component/authentication'
import Preloader from '../component/preloader'

 const Login =()=> {
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	
	const [user, setUser] = useState({
		admissionNumber:'',
		password:'',
		remember:false,
		schoolInfo:[],
		date:new Date().toISOString().slice(0,10),
		dateTime:new Date().toISOString().slice(0,19)
    })
    const [errors, setErrors] = useState({});

    const handleChange = event =>{		
        let {name, value} = event.target;	
        if(name==='remember'){
            value = !user.remember
        }	
       setUser({...user, [name] : value });
       setErrors({...errors, [name]:''});
    }
    

	const fetchSchoolInfo = async ()=>{
		let sql = 'SELECT banner, schoolLogo FROM tbl_basic_details';

        const fd = new FormData();
		fd.append('sql', sql)
        let url = ServerUrl+'/fetch_CarelesSql_controller'
            const {data} = await axios.post(url, fd, config)
			setUser({...user, schoolInfo:data[0]})
    }
	
	
			function submit(event){  
				event.preventDefault();
				if(navigator.onLine){
				let errors = {};   
        
				if(!user.admissionNumber){
					errors.admissionNumber ='Please enter your admission number';
				}
				
				 if(!user.password){
					errors.password ='Please enter your portal PIN';
				} 

				setErrors(errors)

				if(Object.keys(errors).length === 0){
					
				setNotice({...notice,  isLoading: true}) 		
           
        let formData = user;
        const fd = new FormData();
        for(let k in formData){
          fd.append(k, formData[k])
        }
           let url = ServerUrl+'/login_student_controller.php?tablename=tbl_login_user';
		axios.post(url, fd, config)
		.then(response =>{
	if (response.data.type ==='success'){


		//1000 * 60 * SESSION_IDEL_MINUTES
		var inSixtyMinutes = Date.now() + 4199 * 60 * 1000;
		var inOneMinutes = Date.now() + 418 * 60 * 1000;
		var inOneHours = new Date(new Date().getTime() + 419 * 60 * 1000);
		

		Cookies.set("_hisgdstexpt", inOneMinutes, { expires: inOneHours});
		Cookies.set("_hisgdstbexpt", inSixtyMinutes, { expires: inOneHours });


		Cookies.set('hisgdstkauscd', encrypt(response.data.code), {expires: inOneHours })
		Cookies.set('hisgdstkamtk', encrypt(response.data.jwt),  {expires: inOneHours })   
		Cookies.set('hisgdstkarfsh', encrypt(response.data.userToken),  {expires: inOneHours })
		Cookies.set('hisgdstkstnm', encrypt(response.data.studentName),  {expires: inOneHours })
		Cookies.set('hisgdfmcdk', encrypt(response.data.familyCode),  {expires: inOneHours })
		Cookies.set('hisgdsclkysd', encrypt(response.data.classID),  {expires: inOneHours })
		Cookies.set('hisgdssckayd', encrypt(response.data.sectionID),  {expires: inOneHours })


		Alerts('Welcome!', 'success', response.data.message)
		//window.location.href='/dashboard'
		
		var queryString = new URLSearchParams(window.location.search).get("refferer")
		if(queryString!==null){
		  window.open(queryString, '_self')
		}else{
		  window.open('/exam/myresult', '_self')
		}

			}else if (response.data.type ==='error'){
				Alerts('Error!', 'danger', response.data.message)
			}    
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
			window.location.reload()
		}).finally(()=>{
            setNotice({...notice,  isLoading: false }) 
        }) 

	}
	}else{
		Alerts('Error!', 'info', 'No internet connection')
	}    
}

const clearCookies=()=>{
	Cookies.remove('hisgdsclkysd')	
 Cookies.remove('hisgdssckayd')

	Cookies.remove('hisgdstkauscd')	
 Cookies.remove('hisgdstkamtk')
 Cookies.remove('hisgdstkarfsh')
 Cookies.remove('hisgdstkstnm')
 Cookies.remove('_hisgdstexpt')
 Cookies.remove('_hisgdstbexpt')
}

useEffect(()=>{  
 clearCookies()
 fetchSchoolInfo()
},[]);

return (<div  >
			<Preloader />
			<Seo />
			<section  
			className="login-block" style={{backgroundImage: `url(${ImagesUrl}/about/${user.schoolInfo.banner})`, backgroundSize:'cover', backgroundRepeat:'no-repeat'}}>
		  <div className="container">
			  <div className="row">
				  <div className="col-sm-12">
					  
	  <form className="md-float-material form-material" role={'presentation'} autoComplete='off'>
		 
	  <div className="auth-box card">
		  <div className="card-block">
		  <div className="row m-b-20">
				  <div className="col-md-12">
				  <div className="text-center">
		  <img src={ImagesUrl+'/about/'+user.schoolInfo.schoolLogo} className='img-100'  alt="logo.png" />
		  </div>
					  <h3 className="text-center">LEARNERS</h3>
				  </div>
			  </div>
			  <div className="form-group form-primary">
  
				  <input type="text"  name="admissionNumber"   value={user.admissionNumber} placeholder="Learner's Admission Number" onChange={handleChange} className={errors.admissionNumber  ? 'form-control formerror' : 'form-control'} /> 
				 
  
			  </div> <span className=" form-bar error">{ errors.admissionNumber}</span>
			  <div className="form-group form-primary">
			  <input type="text" id="password" name="password"   value={user.password} placeholder="Portal PIN" onChange={handleChange} className={errors.password  ? 'form-control formerror' : 'form-control'} /> 
			  </div>

			  <span className=" form-bar error">{errors.password }</span>
			  <div className="row m-t-25 text-left">
				  <div className="col-12">
					  <div className="checkbox-fade fade-in-primary d-">
						  <label>
							  <input type="checkbox" checked={user.remember} id="remember" name="remember" onChange={handleChange} />
							  <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-primary"></i></span>
							  <span className="text-inverse">Remember me</span>
						  </label>
					  </div>
					{/*   <div className="forgot-phone text-right f-right">
						  <a href="#!" className="text-right f-w-600"> Forgot Password?</a>
					  </div> */}
				  </div>
			  </div>
			  <div className="row">
				  <div className="col-md-12">
				  {notice.isLoading ?	<button type="button" disabled className="btn btn-primary btn-md btn-block  text-center m-b-20"><i className='fa fa-refresh fa-spin'></i> Please wait ... </button>:
				  
				  <button type="button" onClick={submit} className="btn btn-primary btn-md btn-block waves-effect waves-light text-center m-b-20">Sign in</button>}


				  </div>
			  </div>
			  <div className="row">
				<div className="col-md-10">
					<p className="text-inverse text-left m-b-0">Thank you.</p>
					<p className="text-inverse text-left"><a href={KAYUS_ULR} target={'_blank'}><b className="f-w-600">Powered By KAYUS</b></a></p>
				</div>
				<div className="col-md-2">
					
				<img src={ImagesUrl+'/about/kayus.png'} alt="KAYUS" className="img-30" />
				</div>
			</div>
		  </div>
							  </div>
						  </form>
				  </div>
			  </div>
		  </div>
	  </section>
  
  
  
		   
  </div> 


 );
        
}

export default React.memo(Login) 