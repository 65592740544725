import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, schoolName, config, ServerUrl,  Token,  classID, sectionID} from '../component/include'
import {  getClassName, getTime, longDate} from '../component/globalFunction'

const ClassTimetable =()=> {

    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    const [CurrentSession, setCurrentSession] = useState('')
    const [activities, setActivities]=useState([])
    const [schoolInfo, setSchoolInfo]=useState([])
   
    const [schedule, setSchedule]=useState({
        schoolClass:[],
        weekDate:''
    });

    const [schoolClass, setSchoolClass]=useState([]);
    const [subject, setSubject]= useState([]) 
    const [scheduleList, setScheduleList] = useState([]);
    
   
    
    const fetchSchoolInfo =()=>{
        const fd = new FormData();
        fd.append("jwt", Token);
        let url = ServerUrl + '/fetch_controller/tbl_basic_details'
        axios.post(url, fd, config).then(result=>setSchoolInfo(result.data))

    }
    const fetchSchoolClass =()=>{
        const fd = new FormData();
        fd.append("jwt", Token);
        let url = ServerUrl + '/fetchField/tbl_school_class/schoolClass/ID/1'
        axios.post(url, fd, config)
        .then(result => setSchoolClass(result.data))
        .catch((error)=>Alerts('Error!', 'danger', JSON.stringify(error.message))) 

    }

    const fetchSubject =()=>{
        const fd = new FormData();
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetch_controller/tbl_subject'
        axios.post(url, fd, config).then(result=>setSubject(result.data))
    }
    


    const  fetchActivities =()=>{  
        
        const fd = new FormData();
        fd.append("jwt", Token);
   let sql = "Select ID, activity from tbl_activities order by activity ASC"
       let url = ServerUrl+'/fetchBySql_controller'
       fd.append("sql", sql)
       axios.post(url, fd, config).then(result =>setActivities(result.data)) 
   
    }  

    const  fetchTableList =(current)=>{  

        const fd = new FormData();
        fd.append("jwt", Token);
            let sql = "Select  periodList, day from tbl_timetable where sectionID = '"+sectionID+"' and sessionYear = '"+current+"' and  day IN('Monday', 'Tuesday', 'Wednessday', 'Thursday', 'Friday', 'Saturday', 'Sunday' )"
     
          setNotice({...notice, 
            isLoading: true})
          let url = ServerUrl+'/fetchBySql_controller'
           fd.append("sql", sql)
          
           axios.post(url, fd, config).then(result =>{
           
             if(result.data.length!==0){
                setScheduleList(result.data)
                }else{
                   setScheduleList('')
                } 
        
            }).finally(()=>{
                setNotice({...notice, 
                    isLoading: false})
            })  
    }


 const getSubject =(code)=>{
    const result = subject&&subject.filter(list=>list.code===code);
    const answer = result.map(c=>c.subjectName);
    return  answer  
}


const getPeriod =(ID)=>{
    const result = activities&&activities.filter(list=>list.ID===ID);
    const answer = result.map(c=>c.activity);
    return  answer  
}

const getTableDetails=(data)=>{
    const list = JSON.parse(data)
    return list.map((item, id)=>
    <div key={id} className="attachment-block text-center">  
    <b className="text-green">{item.periodName +'. '+ getPeriod(item.periodType)}</b>
<br/>
<strong className="text-green">{getTime(item.startTime)+ ' ~ '+getTime(item.endTime)} </strong>
<br/>
    <b className="text-green">{getSubject(item.subject)}</b><br/>
      
        
        <br/>
    </div>
    )     
}



  const  handleSession =()=>{

    var sql ="Select  startYear from tbl_school_session where status ='Active'"
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append("jwt", Token);
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config)
    .then(result => {
        if(result.data.length!==0){
        setCurrentSession(result.data[0].startYear)
        
    fetchTableList(result.data[0].startYear)
    }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', JSON.stringify(error.message)) 
    })
    }
useEffect(()=>{
    handleSession()
    fetchSchoolInfo()
    fetchSubject()
    fetchSchoolClass()
    fetchSchoolInfo()
    fetchActivities() 
},[]);



        return (  
            <React.Fragment>
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Class Timetable">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-table"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Timetable</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Class Timetable</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">




                            
    <div className="card z-depth-0">
		<div className="card-block">
	
        {schoolInfo&&schoolInfo.map((st, id)=>
                       <div className="row" key={id}>

<table className="table  table-full-width">
       <tbody>
       <tr>
       <td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}  </div>
<div style={{textAlign:'center'}}> {st.mobile}, {st.altMobile}, {st.email} </div>

<div style={{textAlign:'center'}}>  Website: {st.website}  </div>
 </td>
                </tr>
            </tbody>
       </table>

</div>       )}
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>{CurrentSession} Class Timetable </b></u></h5>
        </div></div><br/>



<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td >Class: <b>{getClassName(classID, sectionID, schoolClass)}</b></td>
                    <td>Date Printed: <b>{longDate(new Date())} </b></td>
                </tr>
            </tbody>
       </table>
       {scheduleList.length!==0?
       <table className="table table-bordered">
            <thead>
                <tr>
                    <th>Monday</th>
                    <th>Tuesday</th>
                    <th>Wednessday</th>
                    <th>Thursday</th>
                    <th>Friday</th>
                    <th>Saturday</th>
                    <th>Sunday</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                    {scheduleList&&scheduleList.map((list, index)=>list.day==='Monday'? <span key={index} style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{getTableDetails(list.periodList)}</span>:' ')}
                        </td>
                        <td>
                    {scheduleList&&scheduleList.map((list, index)=>list.day==='Tuesday'? <span key={index} style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{getTableDetails(list.periodList)}</span>:' ')}
                        </td>
                        <td>
                    {scheduleList&&scheduleList.map((list, index)=>list.day==='Wednessday'? <span key={index} style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{getTableDetails(list.periodList)}</span>:' ')}
                        </td>
                        <td>
                    {scheduleList&&scheduleList.map((list, index)=>list.day==='Thursday'? <span key={index} style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{getTableDetails(list.periodList)}</span>:' ')}
                        </td>
                        <td>
                    {scheduleList&&scheduleList.map((list, index)=>list.day==='Friday'? <span key={index} style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{getTableDetails(list.periodList)}</span>:' ')}
                        </td>
                        <td>
                    {scheduleList&&scheduleList.map((list, index)=>list.day==='Saturday'? <span key={index} style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{getTableDetails(list.periodList)}</span>:' ')}
                        </td>
                        <td>
                    {scheduleList&&scheduleList.map((list, index)=>list.day==='Sunday'? <span key={index} style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{getTableDetails(list.periodList)}</span>:' ')}
                        </td>

                </tr>
            </tbody> 
        </table>:<div className="alert alert-danger background-danger">No Record Found</div>}

 </div>	 </div> 


 </div>
        </div>


   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
</React.Fragment>
 );
        
}

export default React.memo(ClassTimetable) 