import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token,  config, classID, sectionID} from '../component/include'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { getDays, longDate, getClassName } from '../component/globalFunction'

import { useSelector } from 'react-redux'
import { Alerts } from '../component/notify'
const Dashboard =()=> {
    
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})
const [CurrentSession, setCurrentSession] = useState('')
    const [allHolidays, setAllHolidays]= useState([])    
   
const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
    const fetchAllHolidays =()=>{
      
        const fd = new FormData();
        fd.append("jwt", Token);
        let sql = "Select * from tbl_events_holidays where eventType='Holidays' and sessionYear = '"+CurrentSession+"'  order by startDate DESC"
     
            let url = ServerUrl+'/fetchBySql_controller'
          
            fd.append("sql", sql)
            axios.post(url, fd, config).then(result =>{
                 if(result.data.length!==0){
                setAllHolidays(result.data)
                } 
            }) 
    }

    const tableHeader = [
        {dataField: 'eventType', text: 'Title', editable:false},
        {dataField: 'title', text: 'Reason', sort:true, editable:false},
        {dataField: 'startDate', text: 'Date',sort:true, editable:false, formatter: (cell, row)=>`${longDate(cell)} `},       
        {dataField: 'endDate', text: 'Day', editable:false, sort:true, formatter: (cell, row)=>getDays(row.startDate)}
     ];


     const HolidayTable=(props)=>{

         const { SearchBar } = Search;
     
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
             showTotal: true,
         paginationTotalRenderer: customTotal,
         sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
         { text: 'All', value: props.data.length }]
            
           };
      
         return <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 &nbsp;
                               
                                 </div>
                                 
                          
                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                    
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
    


      const  handleSession =()=>{

        var sql ="Select  startYear from tbl_school_session where status ='Active'"
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config)
        .then(result => {
            if(result.data.length!==0){
            setCurrentSession(result.data[0].startYear)
        }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        }

        var className =  getClassName(classID, sectionID, schoolClass);
        var cName = "Class -"+className


useEffect(()=>{
    fetchAllHolidays();
    handleSession()
},[]);


        return (  
            <React.Fragment>
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title={cName}>
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-dashboard"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Dashboard</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">


<div className="row">

   


<div className='col-sm-2'>

<button className="btn " onClick={()=>window.open('/attendance/student_attendance', '_self')} style={{height:'100%', width:'100%'}} ><i className="fa fa-calendar fa-2x"></i> 
    <br/>Attendance </button>
</div>

<div className='col-sm-2'>

<button className="btn " onClick={()=>window.open('/library/books', '_self')} style={{height:'100%', width:'100%'}} ><i className="fa fa-book fa-2x"></i> 
    <br/>Library </button>
</div>

<div className='col-sm-2'>
<div className="label-icon">
<button className="btn btn-inverse" onClick={()=>window.open('/fees/fees_payment', '_self')} style={{height:'100%', width:'100%'}} ><i className="fa fa-money fa-2x"></i> 
    <br/>Fees Payment </button>
</div>

</div>


<div className='col-sm-2'>

<button className="btn btn-inverse" onClick={()=>window.open('/exam/myresult', '_self')} style={{height:'100%', width:'100%'}} ><i className="fa fa-file fa-2x"></i> 
    <br/>My Result </button>
</div>

<div className='col-sm-2'>

<button className="btn btn-app" onClick={()=>window.open('/timetable/class_timetable', '_self')} style={{height:'100%', width:'100%'}} ><i className="feather icon-calendar fa-2x"></i> 
    <br/>Class Timetable </button>
</div>


<div className='col-sm-2'>

<button className="btn btn-app" onClick={()=>window.open('/student/noticeboard', '_self')} style={{height:'100%', width:'100%'}} ><i className="fa fa-bar-chart fa-2x"></i> 
    <br/>Noticeboard </button>
</div>

</div>


<br/>




<div className="row  ">   <div className="col-sm-12">
                               
                                                
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Event and Holidays</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximize full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            <div className="col-md-12 table-responsive">

         {notice.isDataFetching ? <TableLoader />:
         <HolidayTable data={allHolidays}  columns={tableHeader}  /> 
        } 

            </div></div>
    
    </div>
    
    </div>
    </div>




   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
</React.Fragment>
 );
        
}

export default React.memo(Dashboard) 