import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {classID,  sectionID, ServerUrl,  studentCode, schoolName,  Token, config, ImagesUrl} from '../component/include'

import Select from 'react-select'
import {  longDate, FormatNumber, getClassName} from '../component/globalFunction'
import { userToken } from '../component/authentication'

const DisplayStudentAttendance =()=> {


    const [summary, setSummary] = useState([])
    const [CurrentSession, setCurrentSession] = useState('')
    const [errors, setErrors] = useState({});
    const [result, setResult] = useState([])
    const [sqlQuery, setSqlQuery] = useState([]);
    const [subject, setSubject]= useState([])
    const [schoolInfo, setSchoolInfo]=useState([])
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    
    const [head, setHead]= useState([])
    const [schoolClass, setSchoolClass]=useState([]);
    const [attendance, setAttendance] = useState({
        subject:[],
        attendanceType:'fullday',
        attendanceMonth:'',
    })
    const  handleSession =()=>{

        var sql ="Select  startYear from tbl_school_session where status ='Active'"
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config)
        .then(result => {
            if(result.data.length!==0){
            setCurrentSession(result.data[0].startYear)
            
        }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        }
    const fetchSchoolInfo =()=>{
        const fd = new FormData();
        fd.append("jwt", Token);
        let url = ServerUrl + '/fetch_controller/tbl_basic_details'
        axios.post(url, fd, config).then(result=>setSchoolInfo(result.data))

    }

    const  handleFetchClass =()=>{
        const fd = new FormData();
        fd.append("jwt", Token);
        let url = ServerUrl + '/fetchField/tbl_school_class/schoolClass/ID/1'
        axios.post(url, fd, config)
        .then(result => setSchoolClass(result.data))
        .catch((error)=>Alerts('Error!', 'danger', JSON.stringify(error.message))) 
         }
       
    
        const fetchSubject =()=>{
            const fd = new FormData();
        fd.append("jwt", Token);
            let url = ServerUrl+'/fetch_controller/tbl_subject'
            axios.post(url, fd, config).then(result=>setSubject(result.data))
        }

        
        const handleSelect= (option, action)=>{
            setAttendance({...attendance, [action.name]:option});
            setErrors({...errors, [action.name]:''})
            setResult('')
        }

        const handleChange=(event)=>{
            const {name, value} = event.target;
            setAttendance({...attendance, [name]:value})

            if(name==='attendanceMonth'){
            const days =  new Date(value.slice(0,4), value.slice(5,7), 0).getDate();
            const start = value+'-01';
            const end = value+'-'+days;
            getDaysBetween(start, end)
            }
            setResult('')
            setErrors({...errors, [name]:''})
        }
       
      
      
        const getDaysBetween =(startDate, endDate)=>{
            const start = new Date(startDate)
            const end =  new Date(endDate)
        
            var result = []
            while (start <= end){
                result.push(new Date(start).toISOString().slice(0,10))
                start.setDate(start.getDate()+1)
            }
    
            let sqlString = result.map(data=>"max(case when a.attendanceDate  = '"+data+"'  then a.indicator else '-' end) AS '"+data.slice(8,10)+getDays(data)+"'")
            setHead(result.map(data=>data.slice(8,10)+getDays(data)))
    
            let final =   'SELECT s.studentName, s.admissionNumber, sum(case when a.indicator  = "P" then 1 else 0 end) as P, sum(case when a.indicator  = "A" then 1 else 0 end) as A, '+String(sqlString)+ ' from tbl_students s, tbl_student_attendance a where   s.code = a.studentCode and a.attendanceDate >= "'+startDate+'" and a.attendanceDate <= "'+endDate+'" and a.studentCode = "'+studentCode+'"'


            setSqlQuery(final)
        }

        const getDays=(date)=>{
            let day;
            switch (new Date(date).getDay()){
                case 0:
                    day =" Sun";
                    break;
                case 1:
                    day =" Mon";
                    break;
                case 2:
                    day =" Tue";
                   break;
                case 3:
                    day =" Wed";
                        break;
                case 4:
                    day =" Thu";
                        break;
                case 5:
                    day =" Fri";
                    break;
                case 6:
                    day =" Sat"
                    break;
                default:
                    day=" Sun"
            }
                return day
            }
    
            const fetchResult =()=>{
                 if(attendance.attendanceMonth===''){
                    setErrors({...errors, attendanceMonth:'Please select attendnace month'})
                }else if(attendance.attendanceType==='subject' && attendance.subject.length===0){
                    setErrors({...errors, subject:'Please select subjcet'})
                }  else{
                    
                
                    fetchSummary(CurrentSession)
                    var sql = sqlQuery

            
            if(attendance.subject.length!==0){
                sql = sql + '  and a.subject = "'+attendance.subject.value+'"'
            }

            sql = sql+ ' and a.attendanceType = "'+attendance.attendanceType+'" group by a.studentCode '
            
            
  setNotice({...notice, isLoading: true}) 
  const fd = new FormData();
  fd.append("jwt", Token);
            fd.append("sql", sql);
            let url = ServerUrl+'/fetchBySql_controller'
            axios.post(url, fd, config).then(result=>{
                if(result.data.length!==0){
                    setResult(result.data)
                    window.scrollTo(0, document.body.scrollHeight)
                }else{
                    Alerts('Message!', 'info', 'There were no attendance for this month')
                }
                
            })
            .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
            }).finally(()=>{
                setNotice({...notice, isLoading: false})
                
            })  
        } 
            }
    
     

      
        
     const fetchSummary =(current)=>{
        let sql =   'SELECT s.studentName, s.admissionNumber, sum(case when a.indicator  = "P" then 1 else 0 end) as P, sum(case when a.indicator  = "A" then 1 else 0 end) as A from tbl_students s, tbl_student_attendance a where s.code = a.studentCode  and a.studentCode = "'+studentCode+'" and a.sessionYear = "'+current+'" and a.sectionID = "'+sectionID+'" and a.attendanceType = "fullday" group by a.studentCode'

          setNotice({...notice, isLoading: true}) 
                const fd = new FormData();
                fd.append("sql", sql);
                
                fd.append("jwt", Token);
                let url = ServerUrl+'/fetchBySql_controller'
                axios.post(url, fd, config).then(result=>{
                    if(result.data.length!==0){
                        setSummary(result.data)
                    }
                    
                })
                .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
                }).finally(()=>{
                    setNotice({...notice, isLoading: false})
                    
                })  
            } 

     useEffect(()=>{
         handleFetchClass()
        fetchSchoolInfo()
        fetchSubject()
        handleSession()
    },[]);


        return (  
            <React.Fragment>
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Search  Attendance">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-calendar"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Attendance</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Student Attendance</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  ">
						
                        <div className="col-sm-12">
                    
                        <div className="card z-depth-0">
                            <div className="card-header">
                                <h5><i className="fa fa-edit" ></i> Student Attendance</h5>
                                <div className="card-header-right">
                                    <ul className="list-unstyled card-option">
                                        <li><i className="feather icon-maximizes full-card"></i></li>
                                        <li><i className="feather icon-minus minimize-card"></i></li>
                                        <li><i className="feather icon-trash-2 close-card"></i></li>
                                    </ul>
                                </div>
                            </div>
                            
                            
                            <div className="card-block">
            
        <section className="col-md-12">
        <span className="pcoded-badge label bg-simple-c-pink">Color Icon For Absent</span>
        <span className="pcoded-badge label bg-simple-c-green">Color Icon For Present</span>
        </section>


        <div  className="card-block">
        <div  className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
      
      <section className="col-md-6">
      <div className="form-group">
   <label >Attendance Month (yyyy-mm)</label>
   <input type="month" className={errors.attendanceMonth ? 'form-control form-control-danger' : 'form-control'} name="attendanceMonth"   onChange={handleChange} value={attendance.attendanceMonth} />

   <span style={{color:'red'}}>{errors.attendanceMonth && errors.attendanceMonth}</span>
               </div>

   

</section>
{/* 
<section className="col-md-6">
<div className="form-group">
                    <label >Type</label>
                    
                    <div className="form-radio">
                <div className="radio radio-inline"><label>
						<input type="radio" checked={attendance.attendanceType ==="fullday"} onChange={handleChange} value="fullday" name="attendanceType" />
										<i className="helper"></i>Full Day</label></div>

                                        <div className="radio radio-inline"><label>
						<input type="radio" checked={attendance.attendanceType ==="subject"} onChange={handleChange} value="subject" name="attendanceType"  />
										<i className="helper"></i>Subject Wise</label></div>

                    </div>
                    
                    </div>

</section> 	
      <section className="col-md-6" style={{display:attendance.attendanceType ==="fullday"?'none':'block'}}>


      <div className="form-group">
						<label >Subject<span style={{color:'red'}}>*</span></label>
                        <Select options={
                            subject&&subject.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.subjectName, abr:list.abbreviation }
                             })} 
                             getOptionLabel={option =>`${option.label} - ${option.abr}`}
onChange={handleSelect} className={errors.subject ? 'form-control form-control-danger' : ''} name="subject" value={attendance.subject}  /> <span style={{color:'red'}}>{errors.subject && errors.subject}</span>	
			</div>
      
      </section>
*/}	

<section className="col-md-6"><br/>
	
	<button type="button" id="submit" onClick={()=>fetchResult()} className="btn btn-primary "><i className="fa fa-search"></i> Search Attendance</button>
										</section>	
				</div>	

                            
                            
                            </div></div> 
							</div></div>
</div>
</div>
</div>

{result.length!==0?
    <div className="card z-depth-0">
		<div className="card-block">
	
        {schoolInfo&&schoolInfo.map((st, id)=>
                       <div className="row" key={id}>

<table className="table  table-full-width">
       <tbody>
       <tr>
       <td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}  </div>
<div style={{textAlign:'center'}}> {st.mobile}, {st.altMobile}, {st.email} </div>

<div style={{textAlign:'center'}}>  Website: {st.website}  </div>
 </td>
                </tr>
            </tbody>
       </table>

</div> 
                       )}
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>Student Attendance </b></u></h5>
        </div></div><br/>



<div className="row">

<div className="col-md-12">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td >Class: <b>{getClassName(classID, sectionID, schoolClass)}</b></td>
                    <td >Month: <b>{attendance.attendanceMonth}</b></td>
                </tr>
            </tbody>
       </table>
       
<div className=" table-responsive">
       <table className="table table-striped  table-bordered">
        <thead>
                <tr>
                <th>S/N</th>
                <th>Admission No</th>
                    <th>Student Name</th>
                    <th>P</th>
                    <th>A</th>
                     {head&&head.map((data, id)=><th key={id} >{data}</th>)} 
                </tr>
              </thead>
              <tbody>
              {result.length!==0?result.map((st, idx)=> 
                <tr key={idx}>
                <td>{idx+1}</td>
                <td>{st.admissionNumber}</td>
                    <td>{st.studentName}</td>
                <td>{st.P}</td>
                    <td>{st.A}</td>            
                    {head&&head.map((data, id)=><td key={id} >
                    {st[data]==='P'?
                    <span className="pcoded-badge label label-success">{st[data]}</span>:
                    st[data]==='A'?
                    <span className="pcoded-badge label label-danger">{st[data]}</span>:
                    st[data]} </td>)} 
                </tr>):[]}
              </tbody>

        </table>
 </div>	 </div> 
 </div>




 </div>
        </div>:''}



        {summary.length!==0?


    <div className="card z-depth-0">
		<div className="card-block">
	
        {schoolInfo&&schoolInfo.map((st, id)=>
                       <div className="row" key={id}>

<table className="table  table-full-width">
       <tbody>
       <tr>
       <td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}  </div>
<div style={{textAlign:'center'}}> {st.mobile}, {st.altMobile}, {st.email} </div>

<div style={{textAlign:'center'}}>  Website: {st.website}  </div>
 </td>
                </tr>
            </tbody>
       </table>

</div> 
                       )}
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b> Attendance Summary</b></u></h5>
        </div></div><br/>



<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td >Class: <b>{getClassName(classID, sectionID, schoolClass)}</b></td>
                    <td >Session: <b>{CurrentSession}</b></td>
                    <td> Date Printed: <b>{longDate(new Date())} </b></td>
                </tr>
            </tbody>
       </table>
       <table className="table table-striped table-bordered">
        <thead>
                <tr>
                <th>Admission No</th>
                    <th>Student Name</th>
                    <th>Total Attendance</th>
                    <th>Total Present</th>
                    <th>Total Absent</th>
                    <th>Present (%)</th>
                    <th>Absent (%)</th>
                </tr>
              </thead>
              <tbody>
              {summary.length!==0?summary.map((st, idx)=> 
                <tr key={idx}>
                <td>{st.admissionNumber}</td>
                    <td>{st.studentName}</td>
                    <td>{Number(st.P) + Number(st.A)}</td>
                    <td>{st.P}</td>
                    <td>{st.A}</td>
                    <td>{FormatNumber(((100*Number(st.P)) / (Number(st.P) + Number(st.A) )))}</td>
                    
                    <td>{FormatNumber(((100*Number(st.A)) / (Number(st.P) + Number(st.A) )))}</td>
                </tr>):[]}
              </tbody>

        </table>
 </div>	 </div> 


 </div>
        </div>:''}



   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
</React.Fragment>
 );
        
}

export default React.memo(DisplayStudentAttendance) 