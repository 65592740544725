
import {Helmet} from "react-helmet";
import { ImagesUrl, mainUrl } from "./include";

const Seo =()=> {
  return (
    <Helmet>

    <title>LEARNERS:. THE HERITAGE SCHOOL OF EXCELLENCE </title>
    
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=0, minimal-ui" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />


    <meta name="description" content="Welcome to THE HERITAGE SCHOOL OF EXCELLENCE, was established in 2nd October, 2013 at No 675, Street 5, Behind Wreca Goron Dutse, Gwale Local Government Kano State...."/>
    
<meta  name="keywords" content="SMS, Portal, Student Result, Staff Login, Admin Login, THE HERITAGE SCHOOL OF EXCELLENCE, Abuja, Kano, schools in kano, kano schools in nigeria, Haske School" />

    <meta name="author" content="Admotek Solutions-08032950881" />

    <meta property="og:title" content="LEARNERS:. THE HERITAGE SCHOOL OF EXCELLENCE"/> 
    <meta property="og:site_name" content="HSE"/> 
    <meta property="og:description" content="Welcome to THE HERITAGE SCHOOL OF EXCELLENCE, Our School is a modern and state of the art institution, we impact Knowledge....bridge to greatness...."/> 
    <meta property="og:image" content={ImagesUrl+'/background/main.png'}/>  
    <meta property="og:url" content={mainUrl}/> 

    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="630" />

    <meta name="twitter:title" content="LEARNERS:. THE HERITAGE SCHOOL OF EXCELLENCE"/> 
    <meta name="twitter:card" content="HSE"/> 
    <meta name="twitter:description" content="Welcome to THE HERITAGE SCHOOL OF EXCELLENCE, Our School is a modern and state of the art institution, we impact Knowledge....bridge to greatness...."/> 
    <meta property="og:image" content={ImagesUrl+'/background/main.png'}/>  
    <meta name="twitter:url" content={mainUrl}/> 



    <meta property="og:type" content="website"/> 
    <meta property="og:publisher" content="Admotek Solutions-08032950881"/>


    </Helmet>
  )
}



export default Seo